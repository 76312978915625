body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.add-edit-buttons-group{
  padding-top: 15px;
  align-items: center;
  height: 100%;
  text-align: right;
}
.add-buttons-plus{
  padding-top: 23px;
  align-items: center;
  height: 100%;
  text-align: right;
}
.add-button-plus-round{
  height: 40px !important;
  width: 40px !important;
  padding: 0 !important;
  min-width: 0px !important;
  border-radius: 250px !important;
}
.MuiAccordionSummary-expandIconWrapper{
  transform: rotate(-90deg) !important;
  color: #1976d2!important;
}
.MuiAccordionSummary-expandIconWrapper.Mui-expanded{
  transform: rotate(0deg) !important;
  color: #1976d2!important;
}
.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters{
  flex-direction: row-reverse;
  margin-left: 10px;
  color: #1976d2;
  font-weight: bold;
}

/*black*/
/* 
.MuiDrawer-paper{
	background-color: rgb(11, 14, 17)!important;
	color: rgba(255, 255, 255, 0.87)!important;
}
.MuiTypography-root{
	color: rgba(255, 255, 255, 0.87)!important;
}
.MuiIconButton-root, .MuiListItemIcon-root, .MuiDataGrid-root, .MuiCheckbox-root, .MuiInputLabel-root, .MuiSvgIcon-root, .MuiOutlinedInput-root, .MuiNativeSelect-select{
	color: rgba(255, 255, 255, 0.87)!important;
}
.css-fxbtpg{
  background-color: rgb(24, 26, 32)!important;
}
.MuiPaper-elevation{
  background-color: rgb(30, 32, 38)!important;
}
.MuiOutlinedInput-notchedOutline{
  border-color: rgba(255, 255, 255, 0.87)!important;
	color: rgba(255, 255, 255, 0.87)!important;
}
.Mui-error{
  color: #d32f2f!important;
} */

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Dodajemo klasu koja će koristiti ovu animaciju */
.fade-in {
  animation: fadeInScale 0.5s ease-out forwards;
}